import React, { useEffect, useRef, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Heading } from '../../components/ui';
import { global } from '../../constants/globals';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useWindowSize } from '../../hooks/useWindowSize';
import { cn } from '../../utils/classNames';

// get website name from url, eg. 'gemporia'
const urlHost = window.location.host.split('.')[1];
const localStorageName = 'recentlyViewedProducts' + urlHost;

const skuMetaTag = document.getElementsByClassName('sku-cell')[0] as HTMLElement;
let sku;
if (skuMetaTag) {
    sku = skuMetaTag.innerText.toLowerCase();
} else if (window.location.pathname.split('/').filter(function (e) { return e }).slice(1).shift() === 'video') {
    sku = window.location.pathname.split('/').filter(function (e) { return e }).slice(3).shift()!;
}

export default function RecentlyViewed() {
    const [products, setProducts] = useLocalStorage<{ sku: string; slug: string }[]>(
        localStorageName,
        []
    );
    const [displayClearAllButton, setDisplayClearAllButton] = useState(true);
    const size = useWindowSize();

    const removeIndividualItem = (event: React.MouseEvent<HTMLDivElement>, itemSku: string) => {
        event.preventDefault();
        const productsObjectCopy = products.filter((itm) => {
            return itm.sku !== itemSku;
        });
        setProducts(productsObjectCopy);
    };

    const removeAllItems = () => {
        setProducts([]);
        window.localStorage.removeItem(localStorageName);
    };

    useEffect(() => {
        if (products) {
            if (size.width! < 768) {
                if (products.length > 2) {
                    setDisplayClearAllButton(true);
                } else {
                    setDisplayClearAllButton(false);
                }
            } else if (size.width! < 1024) {
                if (products.length > 4) {
                    setDisplayClearAllButton(true);
                } else {
                    setDisplayClearAllButton(false);
                }
            } else {
                if (products.length > 5) {
                    setDisplayClearAllButton(true);
                } else {
                    setDisplayClearAllButton(false);
                }
            }
        }
    }, [products, size]);

    // if there are no saved products, display nothing
    if (!products || products.length === 0) return null;

    // if there is just 1 product saved and it's the same
    // as the current one displaying, display nothing
    if (products && products.length === 1 && sku) return null;

    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);

    return (
        <div className='recentlyViewed'>
            <Heading
                titleText="Recently Viewed"
                titleExtraHtml={
                    global.websiteId.toString() === "163" ? (
                        <>
                            <button ref={prevButtonRef} type='button' data-action='previous'>
                                <i className='icon--circle-chevron-left'></i>
                            </button>
                            <button ref={nextButtonRef} type='button' data-action='next'>
                                <i className='icon--circle-chevron-right'></i>
                            </button>
                        </>
                    ) : null
                }
                subtitleText={global.websiteId.toString() === "163" ? "" : "Changed your mind? Take another look"}
            />
            <Swiper
                modules={[Navigation]}
                navigation={
                    global.websiteId.toString() === "163" ? {
                        prevEl: prevButtonRef.current,
                        nextEl: nextButtonRef.current
                    } : true
                }
                className='product-slider'
                slidesPerView={2}
                slidesPerGroup={2}
                spaceBetween={16}
                breakpoints={{
                    768: {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                    },
                    1024: {
                        slidesPerView: 5,
                        slidesPerGroup: 5,
                    },
                }}
                watchOverflow
            >
                {sku
                    ? products
                        .filter((itm) => {
                              return itm.sku !== sku;
                          })
                        .map((product, _) => (
                              <SwiperSlide key={product.sku}>
                                  <a
                                      className='slider__container slide'
                                      href={`/${global.langCult}/product/${product.slug}/${product.sku}/`}
                                  >
                                      <img
                                          className='slide__image'
                                          src={`${global.productImageURL}300/${product.sku}.jpg`}
                                          loading='lazy'
                                      />
                                      <div
                                          className='button__close'
                                          onClick={(event) => removeIndividualItem(event, product.sku)}
                                          role='button'
                                          aria-roledescription='delete item from recently viewed history'
                                      />
                                  </a>
                              </SwiperSlide>
                          ))
                    : products.map((product, _) => (
                        <SwiperSlide key={product.sku}>
                              <a
                                  className='slider__container slide'
                                  href={`/${global.langCult}/product/${product.slug}/${product.sku}/`}
                              >
                                  <img
                                    className='slide__image'
                                    src={`${global.productImageURL}300/${product.sku}.jpg`}
                                    loading='lazy'
                                  />
                                  <div
                                      className='button__close'
                                      onClick={(event) => removeIndividualItem(event, product.sku)}
                                      role='button'
                                      aria-roledescription='delete item from recently viewed history'
                                  />
                              </a>
                        </SwiperSlide>
                      ))}
            </Swiper>
            {displayClearAllButton && (
                <div
                    className={cn('button__clearAll', global.websiteId.toString() === "163" ? "acs-button acs-button--primary" : "")}
                    onClick={removeAllItems}
                    role='button'
                    aria-roledescription='delete all items from recently viewed history'
                >
                    Clear All
                </div>
            )}
        </div>
    );
}
